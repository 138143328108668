import * as React from "react";
import { css } from "aphrodite";
import { CalendarStyle } from "./CalendarStyle";
import { Icon } from "../../atoms/Icon";

interface IProps {
  onClick(): void;
  styles?: any;
}

export class Close extends React.Component<IProps> {
  public render(): JSX.Element {
    const { styles } = this.props;

    return (
      <span
        onClick={this.props.onClick}
        className={css(CalendarStyle.close, styles)}
      >
        <Icon styleKey="close" />
      </span>
    );
  }
}
