import { StyleSheet } from "aphrodite";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { lineHeight } from "main/javascripts/styles/base/typographyStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";

export interface IStyle {
  block: any;
  arrow: any;
  previousArrow: any;
  nextArrow: any;
  disabledArrow: any;
  month: any;
  year: any;
}

export const HeadingStyle: IStyle = StyleSheet.create({
  block: {
    color: textColor.primaryDarkColor,
    height: "40px",
    width: "200px",
    margin: "0 auto",
    position: "relative",
    padding: space.atom,
  },
  arrow: {
    position: "absolute",
    display: "block",
    cursor: "pointer",
    fontSize: "0.75rem",
    top: "15px",
    padding: "0.8rem 0.4rem",
    width: "30px",
    textAlign: "center",
    color: accentColor.primaryColor,
    userSelect: "none",
    ":hover": {
      color: `transparentize(${accentColor.primaryColor}, 0.6)`,
    },
  },
  previousArrow: {
    left: "10px",
  },
  nextArrow: {
    right: "10px",
    left: "auto",
  },
  disabledArrow: {
    color: textColor.disabledColor,
    ":hover": {
      color: textColor.disabledColor,
    },
  },
  month: {
    margin: 0,
    position: "absolute",
    top: "20px",
    left: "40px",
    right: "40px",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: fontWeight.bold,
    fontSize: fontSize.headline,
    lineHeight: lineHeight.headline1,
    userSelect: "none",
  },
  year: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize.caption,
    paddingLeft: space.atom,
  },
});
