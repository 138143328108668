import * as React from "react";
import {
  getDaysInMonth,
  startOfMonth,
  subMonths,
  addMonths,
  format,
  startOfWeek,
  setDate,
} from "date-fns";
import { Day } from "./Day";
import ja from "date-fns/locale/ja";
import { DayEmpty } from "./DayEmpty";
import { DayLabel } from "./DayLabel";

interface IProps {
  date: Date;
  startDate: Date | null;
  endDate: Date | null;
  onClick(date: Date): void;
  disableDate?: any;
}

export const Days: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { date, startDate, endDate, onClick, disableDate } = props;
  const thisDate: Date = date;
  const daysInMonth: number = getDaysInMonth(date);
  const firstDayDate: Date = startOfMonth(date);
  const previousMonth: Date = subMonths(date, 1);
  const previousMonthDays: number = getDaysInMonth(previousMonth);
  const nextsMonth: Date = addMonths(date, 1);
  const dayOfWeek: Date = startOfWeek(thisDate);
  const days: any = [];
  const labels: any = [];

  for (let i = 1; i <= 7; i += 1) {
    labels.push(
      <DayLabel key={`calendarLabel${i}`}>
        {format(dayOfWeek, "iiiiii", { locale: ja })}
      </DayLabel>
    );
    dayOfWeek.setDate(dayOfWeek.getDate() + 1);
  }

  for (let i: number = firstDayDate.getDay() + 1; i > 1; i -= 1) {
    const calendarOfDate: Date = setDate(
      previousMonth,
      previousMonthDays - i + 2
    );

    days.push(<DayEmpty key={format(calendarOfDate, "dd/LL/yyyy")} />);
  }

  for (let i = 1; i <= daysInMonth; i += 1) {
    const calendarOfDate: Date = setDate(thisDate, i);

    days.push(
      <Day
        key={format(calendarOfDate, "dd/LL/yyyy")}
        onClick={onClick}
        currentDate={date}
        date={new Date(format(calendarOfDate, "yyyy/LL/dd"))}
        startDate={startDate}
        endDate={endDate}
        disableDate={disableDate}
      />
    );
  }

  const daysCount: number = days.length;
  for (let i = 1; i <= 42 - daysCount; i += 1) {
    const calendarOfDate: Date = setDate(nextsMonth, i);

    days.push(<DayEmpty key={format(calendarOfDate, "dd/LL/yyyy")} />);
  }

  return (
    <nav>
      {labels}
      {days}
    </nav>
  );
};
