import * as React from "react";
import { css } from "@emotion/react";
import { FieldError } from "react-hook-form";
import { ILabelProps, Label } from "./Label";
import { ILabelStyle, RadioButton } from "./RadioButton";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { InputError } from "./InputError";
import { FieldDescription } from "./FieldDescription";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { nl2br } from "main/javascripts/utils/StringUtil";
import { IBorderColorStyle } from "../../styles/base/formStyle";

export interface IProps {
  namespace: string;
  label?: ILabelProps;
  checkedValue: string;
  options: any;
  description?: string;
  input?: any;
  error?: FieldError;
  displayStyle?: string;
  widthStyle?: string;
  labelStyleKey?: keyof ILabelStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
}

export const RadioButtonBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const {
    namespace,
    label,
    options,
    description,
    input = {},
    checkedValue,
    error,
    displayStyle,
    widthStyle,
    labelStyleKey,
    borderColorStyleKey,
  } = props;

  let innerStyles = innerStyle;
  if (displayStyle) {
    innerStyles = css`
      ${innerStyles};
      display: ${displayStyle};
    `;
  }
  if (displayStyle === "flex") {
    innerStyles = css`
      ${innerStyles};
      flex-wrap: wrap;
      column-gap: ${space.atom2x};
    `;
  }
  if (widthStyle) {
    innerStyles = css`
      ${innerStyles};
      & > label {
        width: ${widthStyle};
      }
    `;
  }

  return (
    <div css={blockStyle}>
      {label && <Label {...label} />}
      {description && <FieldDescription>{nl2br(description)}</FieldDescription>}
      <div css={innerStyles}>
        {options.map(({ text, value, caption = null }: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <RadioButton
                key={index}
                label={text}
                value={value}
                {...input}
                checked={checkedValue === value}
                labelStyleKey={labelStyleKey}
                borderColorStyleKey={borderColorStyleKey}
              />
              {caption && <div css={captionBlockStyle}>{caption}</div>}
            </React.Fragment>
          );
        })}
      </div>
      {error && (
        <InputError
          namespace={namespace}
          errorMessage={error.message}
          label={input.name}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  width: 100%;
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} ${space.atom2x};
  box-sizing: border-box;
`;
const captionBlockStyle = css`
  padding: 0 0 ${space.atom} 2.125rem;
  ${moreThanBreakpoint("desktop")} {
    padding-left: 1.75rem;
  }
`;
const innerStyle = css``;
