import * as React from "react";
import {
  format,
  addMonths,
  subMonths,
  differenceInCalendarMonths,
} from "date-fns";
import ja from "date-fns/locale/ja";
import { css } from "aphrodite";
import { HeadingStyle } from "./HeadingStyle";
import { Icon } from "../../atoms/Icon";

interface IProps {
  date: Date;
  changeDate(date: Date): void;
  resetDate(): void;
}
export class Heading extends React.Component<IProps> {
  private changePrevMonth: () => void = () => {
    if (!this.canChangePrevMonth()) {
      return;
    }
    this.props.changeDate(subMonths(this.props.date, 1));
  };
  private changeNextMonth: () => void = () => {
    if (!this.canChangeNextMonth()) {
      return;
    }
    this.props.changeDate(addMonths(this.props.date, 1));
  };
  private resetDate: () => void = () => {
    this.props.resetDate();
  };
  private canChangePrevMonth(): boolean {
    const today: Date = new Date();
    const previousMonth: Date = subMonths(this.props.date, 1);
    return differenceInCalendarMonths(today, previousMonth) <= 0;
  }
  private canChangeNextMonth(): boolean {
    const today: Date = new Date();
    const nextMonth: Date = addMonths(this.props.date, 1);
    // amadeusの仕様で361日以降は検索できないが、細かく制御するのはおいおい..
    return differenceInCalendarMonths(nextMonth, today) <= 12;
  }
  public render(): JSX.Element {
    const { date } = this.props;
    const disabledPreviousStyles: any = this.canChangePrevMonth()
      ? null
      : HeadingStyle.disabledArrow;
    const disabledNextStyles: any = this.canChangeNextMonth()
      ? null
      : HeadingStyle.disabledArrow;
    return (
      <nav className={css(HeadingStyle.block)}>
        <a
          className={css(
            HeadingStyle.arrow,
            HeadingStyle.previousArrow,
            disabledPreviousStyles
          )}
          onClick={this.changePrevMonth}
        >
          <Icon styleKey="arrowLeft" />
        </a>
        <span className={css(HeadingStyle.month)} onClick={this.resetDate}>
          {format(date, "LLL", { locale: ja })}
          <span className={css(HeadingStyle.year)}>{format(date, "yyyy")}</span>
        </span>
        <a
          className={css(
            HeadingStyle.arrow,
            HeadingStyle.nextArrow,
            disabledNextStyles
          )}
          onClick={this.changeNextMonth}
        >
          <Icon styleKey="arrowRight" />
        </a>
      </nav>
    );
  }
}
