import * as React from "react";
import { css } from "@emotion/react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";

interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean;
  styleKey?: keyof IStyle;
  styles?: any;
  labelStyleKey?: keyof ILabelStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  validate?: RegisterOptions;
}

export interface ILabelStyle {
  labelDark: any;
  labelLight: any;
  labelMix: any;
}

export const RadioButton: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const {
    styles = {},
    label,
    checked,
    styleKey = "default",
    labelStyleKey = "labelDarkStyle",
    borderColorStyleKey = "none",
    validate,
    ...input
  } = props;
  const {
    exBlockStyle,
    exInputStyle,
    exLabelStyle,
    exLabelCheckedStyle,
    exTextStyle,
  } = styles;
  const { name, value, onChange: onInputChange, ...attributes } = input;
  const { register } = useFormContext();
  const { onChange, ...rest } = register(name, validate);

  // react hook formのonChangeと合成
  const onValueChange = (e) => {
    void onChange(e);
    if (onInputChange) {
      onInputChange(e);
    }
  };

  const labelBaseStyles: any = checked
    ? [labelStyle[styleKey], labelCheckedStyle[styleKey], exLabelCheckedStyle]
    : [
        labelStyle[styleKey],
        radioButtonBorderColorStyle[borderColorStyleKey],
        exLabelStyle,
      ];
  return (
    <label css={[blockStyle, exBlockStyle]}>
      <input
        type="radio"
        value={value}
        aria-checked={checked}
        onChange={onValueChange}
        checked={checked}
        css={[inputStyle, exInputStyle]}
        {...attributes}
        {...rest}
      />
      <span
        css={[labelBaseStyles, exTextStyle, labelColorStyle[labelStyleKey]]}
      >
        {label}
      </span>
    </label>
  );
};

export interface IStyle {
  default: any;
  small: any;
}

const blockStyle = css`
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem 0.75rem 0.5rem 0;
  vertical-align: middle;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  ${moreThanBreakpoint("desktop")} {
    padding: 0.375rem 0.75rem 0.375rem 0;
  }
`;
const inputStyle = css`
  position: absolute;
  left: -1.25rem;
  appearance: none;
`;
const labelBaseStyle = css`
  vertical-align: middle;
  padding-top: 0.0625rem;
  position: relative;
  display: block;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid ${borderColor.secondaryLightColor};
  }
  ${moreThanBreakpoint("desktop")} {
    line-height: 1.6;
  }
`;
const labelStyle: IStyle = {
  default: css`
    ${labelBaseStyle};
    padding-left: 2rem;
    font-size: ${fontSize.body};
    min-height: 1.375rem;
    &:before {
      top: 0;
      left: 0;
      width: 1.25rem;
      height: 1.25rem;
    }
    ${moreThanBreakpoint("desktop")} {
      padding-left: 1.875rem;
      font-size: ${fontSize.caption};
      &:before {
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  `,
  small: css`
    ${labelBaseStyle};
    padding-left: 1.75rem;
    padding-top: 0.0625rem;
    font-size: ${fontSize.caption};
    min-height: 1.25rem;
    &:before {
      top: 0;
      left: 0;
      width: 1.125rem;
      height: 1.125rem;
    }
    ${moreThanBreakpoint("desktop")} {
      padding-left: 1.75rem;
      font-size: ${fontSize.caption};
      &:before {
        top: 0;
        left: 0;
        width: 1.125rem;
        height: 1.125rem;
      }
    }
  `,
};
const labelCheckedBaseStyle = css`
  &:before {
    background: ${accentColor.primaryColor2};
    border-color: ${accentColor.primaryColor2};
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;
const labelCheckedStyle: IStyle = {
  default: css`
    ${labelCheckedBaseStyle};
    &:after {
      top: 0.375rem;
      left: 0.375rem;
      width: 0.625rem;
      height: 0.625rem;
    }
    ${moreThanBreakpoint("desktop")} {
      &:after {
        top: calc((1.25rem + 2px - 0.625rem) / 2);
        left: calc((1.25rem + 2px - 0.625rem) / 2);
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  `,
  small: css`
    ${labelCheckedBaseStyle};
    &:after {
      top: 0.3125rem;
      left: 0.3125rem;
      width: 0.625rem;
      height: 0.625rem;
    }
    ${moreThanBreakpoint("desktop")} {
      &:after {
        top: 0.3125rem;
        left: 0.3125rem;
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  `,
};
const labelColorStyle: ILabelStyle = {
  labelDark: css`
    color: ${textColor.primaryDarkColor};
  `,
  labelLight: css`
    color: #fff;
  `,
  labelMix: css`
    color: ${textColor.primaryDarkColor};
    ${moreThanBreakpoint("tablet")} {
      color: #fff;
    }
  `,
};
const radioButtonBorderColorStyle: IBorderColorStyle = {
  none: css`
    &:before {
      border-color: #fff;
    }
  `,
  primary: css`
    &:before {
      border-color: ${borderColor.primaryDarkColor};
    }
  `,
  secondary: css`
    &:before {
      border-color: ${borderColor.secondaryLightColor};
    }
  `,
};
