import * as React from "react";
import { css } from "aphrodite";
import { $, on, off } from "dom7";
import { Heading } from "./Heading";
import { Days } from "./Days";
import { Close } from "./Close";
import {
  CalendarStyle,
  IStyle,
  defaultStyle,
  transitionStyles,
  enterDuration,
  exitDuration,
} from "./CalendarStyle";
import Transition from "react-transition-group/Transition";
import { isMobile } from "main/javascripts/utils/DeviceUtil";

$.fn.on = on;
$.fn.off = off;

export const FromDateType = "FromDate";
export const ToDateType = "ToDate";

export type DateTypes = typeof FromDateType | typeof ToDateType;

interface IState {
  date: Date | null;
}

interface IProps {
  fromDate: Date | null;
  toDate: Date | null;
  dateType: DateTypes;
  displayedCalendar: boolean;
  onSelect(date: Date): void;
  hideCalendar(): void;
  styles?: any;
  arrowStyleOverride?: any;
  disableDate?: any;
}

export class Calendar extends React.Component<IProps, IState> {
  public ref: any;

  public constructor(props: IProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      date: null,
    };
  }

  public clickOutsideHandler: (e: any) => void = (e: any) => {
    if (
      !e.target.closest(".calendar") &&
      !e.target.closest('input[name$="date"]') &&
      !e.target.closest(".calendar-date-block")
    ) {
      this.resetDate();
      this.props.hideCalendar();
    }
  };

  public componentDidMount(): void {
    const evt: string = isMobile() ? "touchend" : "click";
    $(document).on(evt, this.clickOutsideHandler);
  }

  public componentWillUnmount(): void {
    const evt: string = isMobile() ? "touchend" : "click";
    $(document).off(evt, this.clickOutsideHandler);
  }
  public getDate(dateType: DateTypes, fromDate: any, toDate: any): Date {
    if (dateType === FromDateType && fromDate) {
      return fromDate;
    } else if (dateType === ToDateType && toDate) {
      return toDate;
    } else {
      return new Date();
    }
  }
  public changeDate: (date: Date) => void = (date: Date) => {
    this.setState({ date });
  };
  public resetDate: () => void = () => {
    this.setState({ date: null });
  };
  private onSelect: (date: Date) => void = (date: Date) => {
    this.props.onSelect(date);
    this.resetDate();
    this.props.hideCalendar();
  };
  private getArrowStyle: (dateType: DateTypes) => IStyle = (
    dateType: DateTypes
  ) => {
    if (this.props.arrowStyleOverride) {
      return this.props.arrowStyleOverride === "right"
        ? CalendarStyle.arrowRight
        : CalendarStyle.arrowLeft;
    }
    switch (dateType) {
      case FromDateType:
        return CalendarStyle.arrowLeft;
      case ToDateType:
        return CalendarStyle.arrowRight;
      default:
        return CalendarStyle.arrowLeft;
    }
  };
  public render(): JSX.Element | null {
    const {
      fromDate,
      toDate,
      dateType,
      displayedCalendar,
      styles,
      disableDate,
    } = this.props;
    const date: Date =
      this.state.date || this.getDate(dateType, fromDate, toDate);
    return (
      <Transition
        timeout={{
          enter: enterDuration,
          exit: exitDuration,
        }}
        in={displayedCalendar}
        key={dateType}
        nodeRef={this.ref}
      >
        {(state: any): JSX.Element => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className={`${css(
              CalendarStyle.block,
              this.getArrowStyle(dateType),
              styles
            )} calendar`}
            ref={this.ref}
          >
            <Close onClick={this.props.hideCalendar} />
            <Heading
              date={date}
              changeDate={this.changeDate}
              resetDate={this.resetDate}
            />

            <Days
              onClick={this.onSelect}
              date={date}
              startDate={fromDate}
              endDate={toDate}
              disableDate={disableDate}
            />
          </div>
        )}
      </Transition>
    );
  }
}
