import { StyleSheet } from "aphrodite";
import {
  lineHeight,
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";

export interface IStyle {
  base: any;
  active: any;
  muted: any;
  start: any;
  end: any;
  between: any;
  label: any;
}

const nthChild: any = {
  content: "",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "100%",
  backgroundColor: accentColor.primaryColor,
  width: "20px",
};

const selectedRadius = "0.9375rem";

export const DayStyle: IStyle = StyleSheet.create({
  base: {
    width: "14.28571%",
    display: "inline-block",
    textAlign: "center",
    userSelect: "none",
    cursor: "pointer",
    lineHeight: lineHeight.subHeadline1,
    position: "relative",
    fontSize: fontSize.caption,
    fontWeight: fontWeight.bold,
    margin: "0.375rem 0",
  },
  active: {
    fontWeight: fontWeight.bold,
    backgroundColor: `ransparentize(${accentColor.primaryColor}, 0.95)`,
  },
  muted: {
    fontWeight: fontWeight.regular,
    color: "rgba(0, 0, 0, 0.33)",
    cursor: "initial",
  },
  start: {
    borderRadius: `${selectedRadius} 0 0 ${selectedRadius}`,
    ":nth-child(7n):after": nthChild,
    ":after": {
      display: "none",
    },
  },
  end: {
    backgroundColor: accentColor.primaryColor,
    color: "white",
    borderTopRightRadius: selectedRadius,
    borderBottomRightRadius: selectedRadius,
    ":nth-child(7n + 1):after": nthChild,
    ":after": {
      display: "none",
    },
  },
  between: {
    backgroundColor: accentColor.primaryColor,
    color: "white",
    ":nth-child(7n):after": nthChild,
    ":nth-child(7n + 1):after": nthChild,
  },
  label: {
    textTransform: "uppercase",
    fontWeight: fontWeight.regular,
    color: "rgba(0, 0, 0, 0.33)",
    fontSize: fontSize.caption,
    cursor: "initial",
  },
});
